<template>
  <div v-if="data.fields" class="tableau-embed-wrapper">
    <div :id="data.fields.tableauID" style="position: relative"></div>
  </div>
</template>

<script>
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default {
  name: "TableauEmbed",

  transition: "page",

  props: ["data"],

  methods: {
    createTableauEmbed(tableauID) {
      const container = document.getElementById(tableauID);

      const embedCode = `
        <div class='tableauPlaceholder' id='${tableauID}' style='position: relative; margin: auto;'>
          <object class='tableauViz' style='display:none;'>
            <param name='host_url' value='https://public.tableau.com/' />
            <param name='embed_code_version' value='3' />
            <param name='site_root' value='' />
            <param name='name' value='${this.data.fields.tableauDashboardName}' />
            <param name='tabs' value='no' />
            <param name='toolbar' value='yes' />
            <param name='static_image' value='' />
            <param name='animate_transition' value='yes' />
            <param name='display_static_image' value='yes' />
            <param name='display_spinner' value='yes' />
            <param name='display_overlay' value='yes' />
            <param name='display_count' value='yes' />
            <param name='language' value='en-US' />
            <param name='filter' value='publish=yes' />
          </object>
        </div>
      `;

      container.innerHTML = embedCode;

      const divElement = document.getElementById(tableauID);
      const vizElement = divElement.getElementsByTagName("object")[0];
      if (divElement.offsetWidth > 800) {
        vizElement.style.width = "1000px";
        vizElement.style.height = "827px";
      } else if (divElement.offsetWidth > 500) {
        vizElement.style.width = "1000px";
        vizElement.style.height = "827px";
      } else {
        vizElement.style.width = "100%";
        vizElement.style.height = "1027px";
      }
      const scriptElement = document.createElement("script");
      scriptElement.src = "https://public.tableau.com/javascripts/api/viz_v1.js";
      vizElement.parentNode.insertBefore(scriptElement, vizElement);
    },
  },

  mounted() {
    this.createTableauEmbed(this.data.fields.tableauID);
  },
};
</script>

<style scoped lang="scss">
.tableau-embed-wrapper {
  margin: auto;
  margin-bottom: 30px;
}
.tableauPlaceholder {
  margin: auto;
}
</style>
